<template>
  <base-page
    :title="$t('distributeBills.title')"
    :formData="formData"
    :config="config"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.serviceResourceAssignFind"
      :columns="columns"
      :defaultParams="params"
      :highlightCurrentRow="false"
      row-name="company.name"
      v-model="tableRow"
    >
      <!-- 分配时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 上级企业 -->
      <template v-slot:company="{ row }">
        {{ row.company.name }}
      </template>
      <!-- 详情 -->
      <template v-slot:assignDetailSet="{ row }">
        <p v-for="item in row.assignDetailSet" :key="item.id">
          {{ $t('distributeBills.assignName') }}：{{
            resourceTypeEnumObj[item.type]
          }}，{{ $t('distributeBills.assignNumber') }}：
          {{ getAssignNumber(item) }}
        </p>
      </template>
      <!-- 状态 -->
      <template v-slot:status="{ row }">
        <span v-if="row.status === 'ASSIGNED'" style="color: #2fdd60">
          {{ assignStatusEnum_1Obj[row.status] }}
        </span>
        <span v-else style="color: #ff8a2b">
          {{ assignStatusEnum_1Obj[row.status] }}
        </span>
      </template>
    </table-list>
  </base-page>
</template>

<script>
export default {
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          tag: 'el-select',
          label: this.$t('assignDistribute.status'),
          prop: 'status',
          span: 6,
          tagProps: {
            options: this.$dict.assignStatusEnum(),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        targetCompanyUid: this.$store.getters.company.uid,
      },
      assignStatusEnum_1Obj: this.$util.listToObj(
        this.$dict.assignStatusEnum_1()
      ),
      resourceTypeEnumObj: this.$util.listToObj(this.$dict.resourceTypeEnum()),
      columns: [
        // lang:创建时间
        {
          label: this.$t('distributeBills.createTime'),
          prop: 'createTime',
        },
        // lang:上级企业
        {
          label: this.$t('distributeBills.company'),
          prop: 'company',
          width: '150px',
        },
        // lang:详情
        {
          label: this.$t('distributeBills.assignDetailSet'),
          prop: 'assignDetailSet',
          width: '380px',
        },
        // lang:状态
        {
          label: this.$t('distributeBills.status'),
          prop: 'status',
        },
      ],
    }
  },
  methods: {
    // 获取数量
    getAssignNumber(item) {
      let assignNumber = +item.assignNumber
      if (item.type === 'VIDEO_CALL_DURATION') {
        assignNumber = +parseFloat(assignNumber / 60).toFixed(2)
      } else if (
        item.type === 'CLOUD_RECORDING_STORAGE_SPACE' ||
        item.type === 'OSS_STORAGE_SPACE'
      ) {
        assignNumber = +parseFloat(assignNumber / 1024 / 1024 / 1024).toFixed(4)
      }
      return assignNumber
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
  },
}
</script>
